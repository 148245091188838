@font-face {
  font-family: 'Druk';
  src: url('../fonts/DrukWide-SuperItalic-Web.woff') format('woff'); /* Pretty Modern Browsers */
}

html, body {
  overflow: hidden !important;
  background-color: #FFFF00;
  font-family: 'Druk', 'Courier New', Courier, monospace;
  transition: zoom 2s ease-in-out;
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  padding: env(safe-area-inset); 
  cursor: url('../assets/cursors/rollover.svg'), default;
}

#root {
  overflow: hidden;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-container {
  align-items: center;
}

input:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.mute-icon {
  position: fixed;
  top: 6vh;
  right: 6vh;
  cursor: url('../assets/cursors/pointer.svg'), pointer;
  height: 50px;
  width: 50px;
}

.mute-icon.mute {
  background-image: url('../assets/MuteButton.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30px;
  min-width: 30px;
}

.mute-icon.unmute {
  background-image: url('../assets/UnmuteButton.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30px;
  min-width: 30px;
}