canvas {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;    
    width: 100vw;
    z-index: 5;
    overflow: hidden;
}

.marquee-text {
    visibility: hidden;
    width: max-content;
    top: 0;
    position: fixed;
    font-size: 22px;
    font-family: Druk;
    white-space: nowrap;
}