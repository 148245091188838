.contact-container {
    display: flex;
    flex-direction: column;
    position: relative;
    transform: scale(0);
    transition: 0.25s transform ease-in-out;
}

.contact-input {
  font-family: 'Druk';
  width: 399px;
  height: 60px;
  border-radius: 19px;
  border: 5px solid black;
  text-align: center;
  font-size: 21px;
  color: black;
  margin: 10px 0;
  text-align: center;
}

.contact-input.email {
  animation-delay: 0s;
}

.email.error {
  animation: error .1s;
  animation-iteration-count: 1;
}

.contact-input.instagram {
  animation-delay: 1s;
}

.contact-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: gray;
    align-content: center;
    align-items: center;
  }
  
.contact-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}
  
.contact-input::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
  }

.contact-button {
    font-family: 'Druk';
    width: 413px;
    height: 100px;
    border-radius: 19px;
    border: 5px solid white;
    text-align: center;
    font-size: 21px;
    color: rgb(255, 0, 234);
    margin: 10px 0;
    background-color: black;
    cursor: url('../assets/cursors/pointer.svg'), pointer;
    animation-delay: 1s;
    padding: 15px;
}

.contact-button:hover {
  background-color: white;
  color: black;
  border: 5px solid black;

}

.contact-button > .pink {
  font-size: 17px;
  color: magenta;
}

.contact-input.scale {
  transform: scale(1);
}

.contact-button.scale {
  transform: scale(1);
}

.contact-container.scale {
  transform: scale(1);
}

@keyframes error {
  0% { transform: translate(0px, 0); }
  25% { transform: translate(10px, 0); }
  75% { transform: translate(-10px, 0); }
  100% { transform: translate(0px, 0); }
}