.recycle-container {
    border: 5px solid black;
    box-shadow: -5px 5px;
    position: fixed;
    height: 75vh;
    top: 7vh;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: gray;
    margin-right: 5vw;
    margin-left: 15vw;
    padding: 20px;
    border-radius: 27px;
    background-color: #64FF00;
    font-family: 'Space Mono', monospace;
    padding-right: calc(9vw + 165px);
    padding-left: 3vw;
    font-size: 1.5em;
    overflow-y: auto;
    line-height: 28px;
    padding-top:  5vh;
    padding-bottom: 5vh;
    transform: scale(0) translate(100000px, 10000px);    
    transition: transform 0.5s;
    z-index: 2;
}

.recycle-container.visible {
    transform: scale(1);
}

.recycle-text {
    padding: 20px;
}

.recycle0 {
    margin-top: auto;
}

.recycle2 {
    margin-bottom: auto;
}

.close {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 7;
    background-image: url('../assets/CloseButton.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: url('../assets/cursors/pointer.svg'), pointer;
    bottom: 6vh;
    right: 6vw;
}