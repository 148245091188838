.flavor-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    height: 100%;
    width: 100%;
    background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flavor-rescale {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flavor-container.disappear {
    animation: slideUp .3s forwards;
}

.flavor-rescale > .flip-box {
    height: 100px;
    line-height: 30px;
}

.flavor-icon {
    height: 10vh;
    width: 10vh;
    min-width: 50px;
    font-size: 15px;
    color: black;
    font-family: 'Space Mono', monospace;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 13px;
    border: 3px solid black;
    margin: 10px;
    box-shadow: 0px 5px black;
    cursor: url('../assets/cursors/pointer.svg'), pointer;
    min-height: 100px;
    min-width: 100px;
    font-size: 17px;
}

.flavor-icon:hover {
    
}

.flavor-icon.cherry {
    background-color: red;
}

.flavor-icon.sour {
    background-color: #9BCB41;
}

.flavor-icon.icy {
    background-color: #18C1F3;
}

.flavor-icon.pineapple {
    background-color: #FED478;
}

.flavor-icon.passion {
    background-color: #EE479A;
}

.flavor-icon.mango {
    background-color: #F7911D;
}


.flavor-icon.water {
    background-image: url('../assets/watermelon.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.flavor-icon.ripe {
    background-color: #F58C79;
}

.flavoricons {
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.flavoricons > div {
    display: flex;
}

.flavor-rescale > .flip-box {
    margin-right: 10px !important;
}

.flavor-rescale > .logo {
    left: 0px !important;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}