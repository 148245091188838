.placard-container {
    position: fixed;
    width: 165px;
    text-align: center;
    right: 0;
    margin-bottom: 7vh;
    margin-right: 5vw;
    background-color: white;
    border-radius: 12px;
    border: 5px solid black;
    padding: 20px;
    bottom: 0;
    transform: scale(0);
    transition: 0.5s transform ease-in-out;
  cursor: url('../assets/cursors/pointer.svg'), pointer;
    z-index: 3;
}

.green {
    -webkit-text-stroke: 1px black;
    color: #3cff2f;
    display: inline;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: wrap;
}

.new-green {
    animation-name: greenAnim;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    letter-spacing: 0px;
    display: inline;
}

@keyframes greenAnim {
    0% {
      color: magenta;
      letter-spacing: 2px;
    }

    100% {
        color: #3cff2f;
        letter-spacing: 0px;
    }
  }


.placard-container.hide {
    transform: scale(0);
}

.placard-container:hover > .placard > .placard-info {
    color: magenta;

}

.placard-container.show {
    transform: scale(1);
}

.placard-container.recycle {
    background-color: transparent;
    border: none;
    z-index: 3;
    padding: 25px;
}

.placard-info {
    color: #64FF00;
  cursor: url('../assets/cursors/pointer.svg'), pointer;
}

.placard-info.recycle {
    color: #ffffff;
  cursor: url('../assets/cursors/pointer.svg'), pointer;
}

.placard-info:hover {
    color: magenta;
}