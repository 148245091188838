@media only screen and (max-width: 750px) {
    .mute-icon {
        right: 5vw;
        z-index: 7;

    }
    .flavor-rescale > .flip-box {
        width: 90% !important;
        line-height: 19px !important;
    }

    .flavor-rescale > .flip-box > .flip-box-front > .flip-text {
        font-size: 16px !important;
    }

    .flavor-icon {
        width: 20vw !important;
        height: 20vw !important;
        font-size: 12px !important;
        min-width: 0px !important;
        min-height: 0px !important;
        margin: 5px 2px !important;

    }

    .lever {
        top: 117px !important;
        background-image: url('../assets/Lever.png')!important;
        background-size: contain !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        height: 173px !important;
        width: 78px !important;
        transition: transform 0.8s !important;
        transform-origin: 0 163px !important;  
        position: fixed !important;
        left: calc(50% + 196px) !important;
        z-index: 0 !important;
        will-change: transform !important;
    }

    .lever:after {
        content: '' !important;
      }
      
    .main-container {
        margin-top: 70px;
    }
    .placard-container {
        position: fixed !important;
        left: 0px;
        width: 100% !important;
        height: auto;
        bottom: 4vh !important;
        padding: 0px !important;
        margin: 0 !important;
        font-size: 2vh;
        border: none !important;
        border-radius: 0px !important;
        border-top: 5px solid black !important;
        border-bottom: 5px solid black !important;
    }
    .placard {
        padding: 10px;
    }
    .app-container {
        height: 100%;
        display: flex;
    }
    .media {
        position: relative;
        top: -8vh;
    }

    .scale-container {
        display: flex;
        align-items: center;
    }

    .slot-container::after {
        content: '' !important;
        height: 187px !important;
        display: flex !important;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAEACAYAAACzuVY0AAADo0lEQVR4nO3czUsbQRQA8Ddjvkwrgh9oFGm0isVioQdBLQjWWoJQETXVq2dFpKAeChFPFm8VveXS/gfWU4mHIlpQKEK9lLY52ApVIq2ampqP3SljVbJxNWb3VQTfgwGd7P58O7OZXRhnYHLy7qtYrLMIEGOnv798PR7vdGORO4yBmJio+aCqXVkoIAAIqxXE8nJzs1mMH/8QjwNMT39tNJ3ecYayFBXZXqOCnMOiWYwn/6KqcNssCMkZSnN8/A6/wDlnRurJTFGgABOUkY8NXv0Ms7FBBzaYiw2iX7IdGzQVl9KGDBu0YIM3sUFT8f+/KYoicMFEQpjxToPxuFoYDncYvhfZ0TPlBHC5bFBWdvh1DgPAdwD4ZrGw9fx8x6fq6hsfu7vLluvr3+2fhyY/pNIWzuFPXV3uG7//vgcFTC69vSWL4XDHqUevYVCW1taCUCj0pBYNlGVwsDykqt21aGBOTpZYW3sUQgNlaW8vEqhgdjY7BNEGh4ODfwmigeLogi9l+CKQQAJTQ/c9hjHt+5IQQu+wi4GNjY3Q1NSkqYvFYhAMBmFpaQm2t7fToprx0OfzCb1QVVUEg0ExMDAgbDbbWePixdtQNkNFRQVMTU1BX1/fmcdl3CkSHhkZAbtd/1XcUC+73W5oaGjAA2WWEkUDZVitVlzwrCCQQAIJJJBAAgkkkEACCSSQQAKvPUgggQQSSCCBRkLOYKCBcn5lf19/3p96mUACCcQFUWce5USh0+nEA88LAgkkkEACCSSQQAIJJJBAAgkk8NqDBBJIIIEEEnglQJ21U78MgxLb3NxMrf5hGIxEIrCwsKCpYww2DIEyO7/fD3t7e5p6p5N9yRhUFAXm5ubA5/Od+qytrfg96K2w9nq90NPTozk4kUgcttn8/DwEAgGIRqOaz/PyLOGVlZZblZVvDzvG9NrbsbHqp8l/wDDIOez19pZ4Uy/fCLjLObwYGirX3eEjHSinuoMAMMcYTDgc7LHHU6i/jEuvU6qqnJ9ranKeHy15XweArdnZrYsvKUzNcHi4ajaTk1Pj1H3IOcsom7Sg1coSqCDn7DcqaDb0wAh2hvr/YmECNBV64C42iHsfykX7Vx5Eb8OoTp0pEP3GDmOD6J1y7iYkRkDc4ctiYTuoYGmp/acZEFK2OEu/pj1NaDJ0uewbqKDHU7xmFoTjS3Y4mFhdbXmIAnIOYmbm3ksMDDiHjdHRqmeK0mVqB6iTCAQemNojTRMA8BeVF8HUtFod5gAAAABJRU5ErkJggg==) !important;
        background-size: contain !important;
        background-position: 507px 0px !important;
        background-repeat: no-repeat !important;
        justify-content: center !important;
        position: absolute !important;
        width: 600px !important;
        z-index: -5 !important;
        /* animation: winner 1s infinite step-end; */
        top: 189px !important;
    }

    .slot-container.winner::after {
        animation: winnerMobile 1s infinite step-end;
    }

    .close {
        right: 5vw !important;
        bottom: 7vh !important;
    }
/* 
    .lever.first:after {
        content: 'Pull';
        left: 0px !important;
        position: relative;
        top: -42px !important;
        font-family: monospace;
        font-weight: 900;
        font-size: 40px;
    } */

    .window {
        border: 9px solid black;
    }

    .star-container {
        position: relative !important;
        margin-left: auto;
        margin-right: auto;
        /* height: 150px !important;
        width: 150px !important; */
        font-size: 19px !important;
        line-height: 20px !important;
        margin-top: auto;
    }
    .recycle-container {
        height: 95% !important;
        width: 100%;
        padding: 0px !important;
        padding-bottom: 5vh !important;
        margin: 0 !important;
        top: 0 !important;
        left: 0;
        position: absolute;
        z-index: 5 !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .recycle-subcontainer {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10vh;
        padding-bottom: 5vh;
    }

}

@media only screen and (max-height: 650px) and (max-width: 850px)  {

    .media {
        /* transform: scaleY(0.35) scaleX(0.37) !important; */

    }
    .lever.first:after {
        content: 'Pull';
        left: 0px !important;
        position: relative;
        top: -42px !important;
        font-family: monospace;
        font-weight: 900;
        font-size: 40px;
    }

}

@media only screen and (max-height: 500px) and (max-width: 500px) {
    .marquee-container {
        display: none !important;
    }

    .star-container {
        display: none;
    }

    .placard-container {
        /* width: 90% !important;
        height: 40px;
        left: 0;
        top: 0;
        position: absolute;
        margin-bottom: 80px !important; */
    }

    .lever.first:after {
        content: 'Pull';
        left: 0px !important;
        position: relative;
        top: -42px !important;
        font-family: monospace;
        font-weight: 900;
        font-size: 40px;
    }
}

@media only screen and (max-height: 500px) {
    .flavor-icon {
        height: 30px !important;
        font-size: 12px !important;
        min-height: 0 !important;
    }

    .flavor-rescale > .flip-box > .flip-box-front > .flip-text {
        font-size: 4vh !important;
        line-height: 16px !important;
    }

    .flavor-rescale > .flip-box {
        height: 50px !important;
    }

    .flavor-rescale > .logo {
        height: 10vh !important;
    }
}


@media only screen and (max-height: 300px) {
    .marquee-container {
        display: none !important;
    }

    .star-container {
        display: none;
    }

    .placard-container {
        display: none;
    }
    .flavor-rescale > .logo {
        display: none;
    }

}




