.flip-box {
    background-color: transparent;
    width: 440px;
    height: 75px;
    perspective: 1000px;
    margin-right: 46px;
    margin-bottom: 25px;
    margin-top: 17px;
    transform-style: preserve-3d;
    font-family: 'Druk', 'Courier New', Courier, monospace;
    z-index: 3;
}

.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 3;
    will-change: transform;
}
/* 
.flip-box:hover .flip-box-inner {
    transform: rotateX(180deg);
} */

.flip-box-inner.front {
    transform: rotateX(0deg);
    z-index: 2;

}

.flip-box-inner.back {
    transform: rotateX(180deg);
    z-index: 2;

}

.flip-box-front, .flip-box-back {
    background-color: #eee;
    color: black;
    border-radius: 15px;
    border: 7px solid black;
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: .5s background-color color;
    z-index: 2;
}

.flip-box-front.first, .flip-box-back.first {
  cursor: url('../assets/cursors/pointer.svg'), pointer;

}

.flip-box-winner {
    background-color: black;
    color:  #eee;
    border-radius: 15px;
    border: 7px solid #eee;
    z-index: 3;
}

.flip-box-winner {
  cursor: url('../assets/cursors/pointer.svg'), pointer;
    z-index: 3;
}

.flip-box-back {
    transform: rotateX(180deg) translateY(10px);
    z-index: 2;
}

.flip-text {
    margin: 0px;
    padding: 8px 15px;
    font-family: 'Druk', 'Courier New', Courier, monospace;
    font-size: 20px;
    width: 100%;
    text-align: center;
    z-index: 3;
}

.main-flipcard {
    font-size: 23px;
}

.recycle-flip {
    font-size: 20px;
}

.pink-flipcard {
    color: #ff00ff;
    font-size: 15px;
}

.green {
    -webkit-text-stroke: 2px black; /* width and color */
    color: greenyellow;
    display: inline;
}

#green-text {
    display: inline;
}

.back-text > div {
    display: inline;
}

.new-green {
    animation-name: green;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    letter-spacing: 0px;
    display: inline;
}

@keyframes green {
    0% {
      color: magenta;
      letter-spacing: 2px;
    }

    100% {
        color: greenyellow;
        letter-spacing: 0px;
    }
  }
