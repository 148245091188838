.icon {
  width: 80px;
  height: 100px;
}

h2 {
  width: 100%;
}

.logo {
  background-image: url('../assets/Logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 75px;
  width: 400px;
  margin-bottom: 15px;
  left: 27px;
  position: relative;
}

.slot-container {
  width: 600px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: -1;
}


.loading-screen {
  background-image: url('../assets/LoadingPzaz.png');
}

.slot-container::after{
  content: '';
  height: 600px;
  display: flex;
  background-image: url('../assets/Background.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  position: fixed;
  width: 600px;
  z-index: -5;
  /* animation: winner 1s infinite step-end; */
}

.slot-contact:after, .slot-share:after {
  background-image: none !important;
}

.slot-container.winner::after {
  animation: winner 1s infinite step-end;
  -webkit-animation: winner 1s infinite step-end;
}

.mobile-slot.winner::after {
  animation: none;
  -webkit-animation: none;

}

.app-container.winner {
  animation: winnerMobile 1s infinite step-end;
  -webkit-animation: winnerMobile 1s infinite step-end;
}

/* .slot-container.winner:before {
  animation: winner 5s;
} */

.vid-container {
  margin-right: 35px;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 5px black solid;
  right: 6px;
  position: relative;
}

.slots.final {
  /* width: 454.99px;
  height: 250px; */
  height: 215px;
  width: 454.99px;
}

.pzaz-product {
  background-image: url('../assets/PzazProduct.jpg');
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slots.hidden {
  display: none;
}

.window {
  overflow: hidden;
  height: 215px;
  margin-right: 35px;
  border-radius: 20px;
  border: 5px solid black;
  background-color: #eee;
}

.slots.final > .window {
  /* margin-right: 0; */
}

.top-fade {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, #ffffff9c, #ffffff00, #ffffff00, #ffffff9c);
  z-index: 1;
  border-radius: 20px;

}

.outer-col {
  width: 145px;
  float: left;
  background-color: #eee;
  top: -200px;
  position: relative;
}

.middle-col > .col {
  /* border-left: 5px solid black;
  border-right: 5px solid black; */
}
.middle-col::before, .middle-col::after {
  content: '';
  border-left: 5px solid #000;
  position: absolute;
  height: 100%;
  top: -3%;
}

.middle-col::before {
  left: 0;
}

.middle-col::after {
  right: 0;
  left: none;
}

.col {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -62px;
  position: relative;
  backface-visibility: hidden;
perspective: 1000;
}

.slots.spinning .col1 {
  animation: scroll 5s cubic-bezier(.06, .63, 1, 1.11) 1;
  -webkit-animation:scroll 5s cubic-bezier(.06, .63, 1, 1.11) 1;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
}

.slots.wiggle .col1 {
  animation: wiggle 1.5s ease-in-out infinite;
  -webkit-animation: wiggle 1.5s ease-in-out infinite;

}
.slots.wiggle .col2 {
  animation: wiggle 1.5s ease-in-out infinite;
  -webkit-animation: wiggle 1.5s ease-in-out infinite;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}
.slots.wiggle .col3 {
  animation: wiggle 1.5s ease-in-out infinite;
  -webkit-animation: wiggle 1.5s ease-in-out infinite;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}


.col1 {
  /* animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s; */

}

.slots.spinning .col2 {
  animation: scroll 5s cubic-bezier(.06, .63, 1, 1.11) 1;
  -webkit-animation:scroll 5s cubic-bezier(.06, .63, 1, 1.11) 1;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;

}

.slots.spinning .col3 {
  animation: scroll 5s cubic-bezier(.06, .63, 1, 1.11) 1;
  -webkit-animation:scroll 5s cubic-bezier(.06, .63, 1, 1.11) 1;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

.lever {
  background-image: url('../assets/Lever.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 78px;
  /* perspective: 1000px; */
  transition: transform 0.8s;
  /* -webkit-transform-style: preserve-3d; */
  /* transform-style: preserve-3d; */
  -webkit-transform-origin: 0 275px;
  -moz-transform-origin: 0 275px;
  -o-transform-origin: 0 275px;
  transform-origin: 0 275px;
  position: fixed;
  left: calc(50% + 281px);
  top: calc(50% + -260px);
  z-index: 0;
  will-change: transform;
}

.lever.mobile {
  background-image: url('../assets/Lever.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 173px;
  width: 78px;
  /* perspective: 1000px; */
  transition: transform 0.8s;
  /* -webkit-transform-style: preserve-3d; */
  /* transform-style: preserve-3d; */
  transform-origin: 0 163px;  
  position: fixed;
  left: calc(50% + 196px);
  top: calc(50% + -260px);
  z-index: 0;
  will-change: transform;
}

.lever.first {
  cursor: url('../assets/cursors/pointer.svg'), pointer;
}

.lever.first:after {
  content: 'Pull';
  left: 90px;
  position: relative;
  top: 35px;
  font-family: monospace;
  font-weight: 900;
  font-size: 2.5em;
}

.lever.mobile:after {
  content: '';
}

.lever.active:after {
  content: '';
}

.lever.active {
  transform: rotateX(180deg);
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(calc(-100% + 315px));
  }
  100% {
    transform: translateY(calc(-100% + 320px));
  }
}

@keyframes wiggle {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  70% {
    transform: translateY(0);
  }
}

@keyframes winner {
  0% {
    filter: none;
  }
  20% {
    filter: hue-rotate(260deg) brightness(5);
  }
  40% {
    filter: hue-rotate(465deg) brightness(1.3);
  }
  60% {
    filter: hue-rotate(175deg) brightness(2.1);
  }
  80% {
    filter: hue-rotate(333deg) brightness(1.4);
  }
  100% {
    filter: hue-rotate(65deg) brightness(2);
  }
}

@keyframes winnerMobile {
  0% {
    background-color: #FFFF00;
  }
  20% {
    background-color: magenta;
  }
  40% {
    background-color: #64FF00;
  }
  60% {
    background-color: rgb(0, 174, 255);
  }
  80% {
    background-color: rgb(255, 153, 0);
  }
  100% {
    background-color: rgb(255, 0, 0);
  }
}