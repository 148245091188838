a {
  text-decoration: none;
}

.slot-share {
  width: 550px;
}

.share-window {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: scale(0);
  transition: 0.3s transform ease-in-out;

}

.share-window > .logo {
  left: 0 !important;
}

.window.share {
    background-color: #eee;
    width: 90%;
    position: relative;
    margin-top: 20px;
    font-size: 20px;
    height: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin-right: 0px !important;
  }

  .share-window.scale {
    transform: scale(1);
  }
  
  .share-text {
    margin: 15px;
  }


  .upper {
    margin: 10px 0;
  }

  .share-text.button {
    background-color: black;
    width: 80%;
    color: magenta;
    padding: 20px;
    border-radius: 16px;
    font-size: 30px;
  cursor: url('../assets/cursors/pointer.svg'), pointer;
  }

  .share-text.button:hover {
    background-color: black;
    color: magenta;
  }
  
  .share-link {
    margin-top: -15px;
    color: magenta;
  cursor: url('../assets/cursors/pointer.svg'), pointer;
  }
  
  .share-link:hover {
    color: #64FF00;
  }